@import url('https://fonts.googleapis.com/css?family=Inter');

:root{
  --bs-font-inter: 'Inter';
  --bs-body-main: #F4F4F4 !important;
  --bs-link-color: #146B90 !important;
  --bs-link-color-rgb: 20, 107, 144 !important;
  --bs-primary: #146B90 !important;
  --bs-primary-colors-cyan: #22A298;
  --bs-mid-gray: #CCCCCC;
  --bs-dark-gray: #949494;
}

body {
  margin: 0;
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bs-body-font-family: var(--bs-font-inter);
  scroll-behavior: smooth;
}

code {
  font-family: 'Inter';
}

.location .form-control::-ms-input-placeholder { /* Edge 12-18 */
  text-transform: initial !important;
}

.location .form-control::placeholder {
  text-transform: initial !important;
}

a, a:hover{ 
  color: #146B90;
  cursor: pointer;}

  ::-webkit-scrollbar {
    width: 8px;
    height:10px;
    z-index: 99;
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 10px; 
  }
  
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2); 
    height:10px;
  }

.font-14{
  font-size: 0.875rem;
}

.font-12 {
  font-size: 12px;
}

.font-18{
  font-size: 1.125rem;
}

.font-20 {
  font-size: 1.25rem;
}

.font-26 {
  font-size: 1.625rem;
}

.font-28 {
  font-size: 1.75rem;
}

.font-weight-600 {
  font-weight: 600;
}

.mt-28 {
  margin-top: 28px;
}

.mt-24 {
  margin-top: 24px;
}

.PL-80 {
  padding-left: 80px;
}

.h-40{
  min-height: 40px;
}

.custom-bg{
  background-color: #146B90 !important;
}

.text-primary{
  color: #146B90 !important;
}

.text-mid-gray {
 color: var(--bs-mid-gray);
}

.text-dark-gray {
 color: var(--bs-dark-gray);
} 

.text-green{
  color: var(--bs-primary-colors-cyan);
}

.justify-text{
  text-align: justify;
}

.btn-primary{
  border-radius: 4px !important;
  background: #146B90 !important;
  border-color: #146B90 !important;
  padding: 10px 12px !important;
  font-weight: 500;
}

.btn-secondary{
   border-radius: 4px;
   border: 1px solid var(--1-primary-colors-blue, #146B90) !important;
   background: var(--3-text-colors-white, #FFF) !important;
   color: #146B90 !important;
   border-radius: 4px !important;
}

.btn-activate{
    background-color: var(--bs-primary-colors-cyan) !important;
    color: #fff !important;
}
.btn-md{
  border-radius: 4px !important;
  padding: 10px 35px !important;
  font-weight: 500;
}

.form-control {
  border-color: #49454F !important;
  border-radius: 4px !important;
  padding: 0.75rem 0.75rem !important;
}

.form-control:focus {
  border-color: inherit;
  outline: 0;
  box-shadow: none !important;
}

.error {
  color: #ff0000;
}

.primary_color {color: #146B90;}

.Toastify__toast-container--top-right {
  top: 6em !important;
}

.Toastify__toast-container { z-index: 999999 !important; }

.table>:not(caption)>*>* {
  vertical-align: middle;
  font-size: 14px;
}

.radius-0 {
  border-radius: 0 !important;
}

/* #admin-dash-header {
  background-color: #fff;
  padding: 17px 40px;
  height: 90px;
}

.navbar_logo {
  width: 137px;
  height: 55px;} */

.btn-secondary-color{
  --bs-btn-color: #000 !important;
  --bs-btn-bg: #fff !important;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 130,138,145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #fff;
}

.hori_line {
  margin: 49px 0px;
  border-top: 1px solid #ccc;
}

.header {
  width: 100%;
  height: 90px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 30px 18px 40px;
  background-color: #fff;
  border-radius: 0px 0px 26.67px 26.67px;
  z-index: 9999;
  transition: 0.5s;
}

.navbar_logo {
  width: 137px;
  height: 55px;
}

select#language-picker {
  background-color: transparent;
  border: 0;
  outline: none;
  box-shadow: none;
  font-weight: 600;
}
select#language-picker option{
  color: #191919;
}

.header ul.dropdown-menu {
  border: none;
  transition: top 2s ease 0s;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  text-align: left;
  width: 170px;
  top: 35px !important;
  left: -136px !important;
  transform: none !important;
  pointer-events: all;
}

.avatar {
    width: 50px;
    height: 50px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    cursor: pointer;
    background: #F4B532;
}

.username{
  position: relative;
  font-weight: 600;
  cursor: pointer;
}

.username::before{
  content: " ";
  border-left: 1px solid #ddd;
  margin-right: 0px;
  padding-right: 15px;
  position: absolute;
  height: 25px;
  top: auto;
  left: -14px;
}

.admin-logo {
  position: relative;
  margin-left: 1.5rem;
}

.admin-logo:before {
  position: absolute;
  left: -17px;
  top: auto;
  height: 25px;
  width: 1px;
  content: " ";
  background-color: #ddd;
}

ul.dropdown-menu {
  border-radius: 0px 0px 8px 8px;
  background: #FFF;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.10);
  width: 180px;
  text-align: left;
}

ul.dropdown-menu li:last-child {
  border-bottom: none;
}

ul.dropdown-menu li {
  border-bottom: 1px solid #ccc;
  margin: 0px 7px;
  padding: 5px 0;
}

.dropdown-item.active, .dropdown-item:active, 
.dropdown-item:focus, .dropdown-item:hover {
  color: var(--bs-dropdown-link-color) !important;
  background-color: transparent !important;
}

.header .dropdown-toggle::after {
  display: none;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 20px;
  width: 332px;
  background-color: #FFFFFF;
  /* padding: 0.5rem 1rem 0 0; 
  transition: 1.5s; */
  /* z-index: var(--z-fixed); */
  /* -webkit-box-shadow: 1px -1px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px -1px 5px 0px rgba(0, 0, 0, 0.1); */
  padding: 117px 15.35px 30px 30px;
}

.sidebar-menu{
  height: calc(100vh - 220px);
  transition: 0.5s;
}

.sidebar-menu a.active {
  color: var(--1-primary-colors-blue, #146B90);
  font-weight: 600;
}

.sidebar-menu a.active img {
  filter: invert(67%) sepia(41%) saturate(457%) hue-rotate(154deg) brightness(93%) contrast(89%);
}

.sticky-btn {
  position: fixed;
  bottom: 20px;
  left: 40px;
  right: auto;
  top: auto;
  width: 225px;
}

.admin-dash-nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.page__main{
  padding-top: 2.5rem;
  background-color:  #F4F4F4;
  margin: 50px 0px 0px 332px;
 /* height: 100vh; */ 
}

.loader-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 999999;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background-color: #146b90 !important;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

.location .form-control{
  text-transform: capitalize;
}

@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

.footer {
  border-top: 1.333px solid var(--3-text-colors-light-grey, #E1E1E1);
  padding: 49px 39px;
  height: auto;
  z-index: 9999;
  position: relative;
  background: #fff;

}

.footer-copyright {
  color: #5A5A5A;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5px;
  letter-spacing: 1.333px;
}

.social-link {
  margin-right: 18px;
}

.footer-email {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.footer-logo {
  width: 100%;
  padding: 25px 0px;
}

img.social-icons {
  width: 45px;
}

.dashboard {
  padding: 42px 40px;
}

.dashboard .card  {
  --bs-card-border-color: transparent ;
  --bs-card-border-radius: 20px;
}

.dashboard .card-body {
  padding: 28px 28px;
}

.upload-image {
  border-radius: 5.333px;
  border: 2.333px dashed #CCC;
  padding: 20px;
  margin-top: 32px;
}

.course {
  margin-top: 26px;
}

.button-default {
  font-size: 21px;
  color: #146B90;
}

.edit_btn {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.ReactModal__Overlay{
   background-color: rgba(0, 0, 0, 0.45) !important;
   backdrop-filter: blur(2px) !important;
   z-index: 999999999;
   pointer-events: none;
}

.sidebar-profile .initials {
  position: relative;
  margin: 5px auto;
  font-size: 16px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-profile .initials::after {
  background-color: #F4B532;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: " ";
  z-index: -9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReactModal__Body--open {
  height: 100vh;
  overflow-y: hidden;
  pointer-events: none;
}

.ReactModal__Content {
  position: fixed;
  min-width: auto !important;
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  margin-right: -50% !important;
  border-radius: 20px !important;
  padding: 30px 40px 30px !important;
  transform: translate(-50%, -50%) !important;
  min-width: 600px !important;
  width: 600px;
  position: fixed;
  min-width: auto !important;
  pointer-events: auto;
  outline: none;
}

.ReactModal__Body--open .login-otp {
  pointer-events: all;
}

.continue-modal{
  max-width: 800px;
  width: auto;
  padding: 30px !important;
}

.close{
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 1.5rem;
}

.ReactModal__Content img{
  width: 150px;
  height: 150px;
}

.videoModal{
  position: fixed;
  min-width: auto !important;
}

.card.language-popup {
  max-width: 600px;
}

.statistics{
  border-radius: 20px;
  background: #F4F4F4;
}

.custom-tooltip {
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
}

.tooltip-color {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.tooltip-label {
  font-weight: bold;
}

.table.csv-table {
  font-size: 14px;
  border: 1px solid #000;
  padding: 10px !important;
}

.table.csv-table tr{
  font-size: 14px;
  border-bottom: 1px solid #000;
}

.table.csv-table>:not(caption)>*>*{
  padding: 10px !important;
}

@media only screen and (max-width: 992px) {
  .language-picker{
    display: none;
  }
  .username {
    display: none;
  }
  /* .ReactModal__Content {
    min-width: 94% !important;
    width: 94%;
  } */
}

.add_btn {
  width: 22px;
  height: 22px;
  margin-right: 8px;

}

.thead-dark {
  --bs-table-bg: #CCC;
}

.table>:not(caption)>*>* {
  padding: 15px 0px !important;
  vertical-align: middle;
}

.table {
  border-radius: 5px !important;
}

.has-search .form-control {
  padding-left: 3rem !important;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  padding: 4px 10px;
}

.has-search .form-control {
  width: 25%;
  padding: 0.75rem 0.75rem 0.75rem 2.75rem!important;
  border-radius: 50px !important;
  margin-bottom: 29px;
  border: var(--bs-border-width) solid #146B90;
  font-size: 0.85rem;
}

.licence_box {
  padding: 22.667px 25px;
  border-radius: 13.333px;
  border: 1.333px solid var(--3-text-colors-white, #FFF);
  background: var(--1-primary-colors-blue, #146B90);
  align-items: center;
}

.licence_box .licence-used{position: relative;}

.licence_box .licence-used {
    content: " ";
    border-left: 1px solid #ccc;
    height: 50px;
    align-self: center;
}

.licences_btn {
  float: right;
  width: 250px;
}

.licences_btn button{
    font-size: 1rem;
}

.learning_lable {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 23px;
  color: #000;
}


.learning_course_video{position: relative;}
/*
#playBtn { 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    cursor: pointer;
    visibility: visible;
    background: linear-gradient(270deg, #146B90 0%, #146B90 11.63%, #146B90 24.62%, #146B90 32.37%, rgba(20, 107, 144, 0.00) 65.66%) !important; 
}
#playBtn.playing { opacity: 0; visibility: hidden; z-index: -9;}

.listing.overlay{
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100% !important;
  border: none;
  cursor: pointer;
  visibility: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8) !important; 
  z-index: 99999 !important;
}
video.play{
  height: 100% !important;
  border-radius: 0 !important;
  object-fit: contain !important;
}
*/

#playBtn { 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  visibility: visible;
  background: linear-gradient(270deg, #146B90 0%, #146B90 11.63%, #146B90 24.62%, #146B90 32.37%, rgba(20, 107, 144, 0.00) 65.66%);
}

#playBtn.playing { opacity: 0; visibility: hidden; z-index: -9;}

.listing.overlay {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;
  visibility: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
}

.course-container #playBtn {
  background: transparent !important;
}

.course-video .close-btn {
  position: absolute;
  right: 0;
  top: 0;
}

video.play {
  height: 100% !important;
  border-radius: 0 !important;
  object-fit: contain !important;
}

hr.learning_hr {
  border-radius: 0px 0px 4px 4px;
  padding: 0px 18px;
}

.side_verticalline {
  border-right: 1.5px solid #146B90;
  height: auto;
}

.progressbar_learner {
  width: 45px;
  height: 45px;
  margin: 0px auto;
  margin-bottom: 20px;
}

.Toastify__toast-container--top-right {
  top: 6em !important;
  right: 1em;
}

.css-1fdsijx-ValueContainer {
  padding: 5px 10px !important;
}

.css-1u9des2-indicatorSeparator {
  background-color: transparent !important;
}

.css-1xc3v61-indicatorContainer{
  color: #000000;
}

.btn.disabled-btn:disabled {
  background: #E1E1E1 !important;
  color: #949494 !important;
  border-color: #E1E1E1 !important;
}

#admin-dash-header .css-b62m3t-container > div{
  border-color: transparent !important;
  color: #000000;
  outline: none;
}

.css-b62m3t-container > div:hover{
  border-color: transparent !important;
  outline: none;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #000 !important;
  font-size: 26px !important;
}

.CircularProgressbar-path {
  stroke: #22A298 !important;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

@media screen and (max-width: 768px) {
.ReactModal__Content.videoModal.w-25 {
  width: 90% !important;
}
.ReactModal__Content {
  width: 94%;
}
}

@media screen and (max-width: 992px) {
  body {
    font-size: 85% !important;
  }
  .font-16{
    font-size: 14px;
  }
  .font-26 {
    font-size: 1rem;
  }
  .ReactModal__Body--open {
    height: 100vh;
    overflow-y: auto;
  }
  .ReactModal__Content.videoModal.w-25 {
    width: auto !important;
  }
  .continue-modal{
    max-width: 94%;
    padding: 15px !important;
  }
  .continue-btn{
    font-size: 12px !important;
  }
  .continue-modal p {
    margin-top: 0;
    margin-bottom: 0.8rem;
    font-size: 13px !important;
  }
  .btn-md {
    padding: 8px 30px !important;
    font-size: 0.8rem !important;
  }
  .sidebar {
    width: 270px;
    z-index: 9;
    height: 100vh;
  }
  .sidebar-menu a {
    font-size: 1rem !important;
    margin-bottom: 30px;
  }
 .banner-content {
  bottom: 0;
  position: relative !important;
  right: 0px !important;
  top: 15px!important;
  z-index: 999;
  left: 0;
 }
 .accordion-item .accordion-header span {
  font-size: 14px;
 }
 .accordion-button span:first-child::after {
   top: -3px;
   right: -23px;
   width: 16px;
   background-size: 16px;
 }
 .accordion-button[aria-expanded="false"] span:first-child::after {
   top: 0px;
 }
 .footer {
  padding: 35px 25px 15px;
 }
 .page__main {
  margin: 50px 0px 0px 0px;
  }
 .footer .font-20{
    font-size: 0.938rem;
  }
  img.social-icons {
    width: 25px;
 }
.footer-copyright {
  font-size: 0.938rem;
  padding-bottom: 1.5rem;
  line-height: 1.5;
}
}

@media screen and (min-width: 1024px) {
  .h3, h3 {
    font-size: 1.125rem !important;
  }
  .font-20 {
    font-size: 1rem;
  }
  .header {
    padding: 18px 30px 18px 30px;
  }
  .sidebar {
    width: 200px;
    padding: 117px 15.35px 30px 30px;
  }
  .page__main {
    margin: 50px 0px 0px 200px;
  }
  .sidebar-menu a {
    font-size: 1rem;
    margin-bottom: 20px;
    margin-top: 0rem;
  }
  .sticky-btn {
    width: 170px;
    left: 24px;
  }
  .sticky-btn button {
    font-size: 14px;
  }
  .banner-content {
    position: absolute;
    top: 60px;
    width: 465px;
  }
  .table>:not(caption)>*>* {
    padding: 10px 0px !important;
    font-size: 13px;
  }
  .progressbar .CircularProgressbar {
    width: 35px;
    height: 35px;
  }
  .font-26 {
    font-size: 1.125rem;
  } 
  .btn-md {
    padding: 6px 25px !important;
    font-size: 1rem !important;
  }
  .footer {
    padding: 35px 20px;
   }
 .footer .font-20{
    font-size: 0.938rem;
  }
  img.social-icons {
    width: 30px;
  }
 .footer-copyright {
  font-size: 0.725rem;
  line-height: 1.5;
 }
 .css-1fdsijx-ValueContainer {
  padding: 0rem 0.75rem !important;
}
}

@media screen and (min-width: 1440px) {
  .font-20 {
    font-size: 1.125rem !important;
  }
  .font-26 {
    font-size: 1.25rem !important;
  } 
  .header {
    padding: 18px 30px 18px 40px !important;
  }
  .sidebar {
    width: 270px !important;
    padding: 117px 15.35px 20px 40px !important;
  }
  .page__main {
    margin: 50px 0px 0px 270px !important;
  }
  .sidebar-menu a {
    font-size: 1.25rem !important;
    margin-bottom: 36px !important;
    margin-top: 1rem !important;
  }
  .sticky-btn {
    width: 225px !important;
  }
  .table>:not(caption)>*>* {
    font-size: 15px;
  }
  .progressbar .CircularProgressbar {
    width: 35px;
    height: 35px;
}
  .btn-md {
    padding: 10px 50px !important;
  }
  .banner-content {
    position: absolute;
    top: 130px !important;
    width: 500px !important;
  }
  .social-link {
    margin-right: 18px;
  }
  img.social-icons {
    width: 45px;
  }
  .footer .font-20{
    font-size: 1.125rem !important;
  }
  .footer-copyright {
    font-size: 0.9rem !important;
    line-height: 1.5;
  }
}

.css-1fdsijx-ValueContainer {
  padding: 0.5rem 0.75rem !important;
}

.css-13cymwt-control {
  border-color: #49454F !important;
}

.css-13cymwt-control:hover {
  border-color: #49454F !important;
}

.css-b62m3t-container>div:hover {
  border-color: #49454F !important;
  outline: none;
}
